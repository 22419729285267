/* eslint-disable eqeqeq, react-hooks/exhaustive-deps, no-useless-escape */
import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import { Document, Page } from  'react-pdf/dist/esm/entry.webpack';
import {
    getQueryParam,
    changeFieldsWithPrefixAndCamelCase,
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    googleRecaptcha,
    getVisaCountriesList,
    getCountriesList,
    navigateToPath,
    getExtensionByFileContent,
    getFileType,
    executeSingleRequest,
    genericGetListFromLanguage, convertDateFieldsIntoString,
    isInactiveField,
    getPurposeOfTravelList,
    getBorderOfEntryList, notifyClientError
} from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashCloneDeep from 'lodash/cloneDeep';
import GenericFieldComponent from "../GenericFieldComponent";
import moment from 'moment';
import RegisterBottomButtons from "./RegisterBottomButtons";
import RegisterStage2SubProcess from './RegisterStage2SubProcess';
import CONSTANTS from "../../common/constants";
import { ACTIONS } from '../../redux/actions';
import {CSS_COLORS} from "../../common/cssColors";
import CropHandler from '../CropHandler';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalComponent from "../Modal";
import PdfHandler from "../PdfHandler";

function RegisterStage2Component(props) {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const {
        actions
    } = props;
    const [ showMinorWarning, setShowMinorWarning ] = useState(false);
    const [ minorGoingWithParent, setMinorGoingWithParent ] = useState(false);
    const [ loaders, setLoaders] = useState({ });
    let [ fileUploadsData, setFileUploadsData ] = useState(getRelevantUploadedFilesData());
    // crop handler
    let [ cropHandlerControls , setCropHandlerControls ] = useState({ // eslint-disable-line no-unused-vars
        passport: {},
        personalIdWithAppendix: {},
        healthGreenCardOrVaccination: {},
        passportFace: {},
        passportBackside: {},
        birthCertificate: {},
        parentPassport: {}
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });
    cropHandlerControls.passportFace.callback = cropHandlerCallback.bind({ fileType: 'passportFace' });
    cropHandlerControls.personalIdWithAppendix.callback = cropHandlerCallback.bind({ fileType: 'personalIdWithAppendix' });
    cropHandlerControls.healthGreenCardOrVaccination.callback = cropHandlerCallback.bind({ fileType: 'healthGreenCardOrVaccination' });
    cropHandlerControls.passportBackside.callback = cropHandlerCallback.bind({ fileType: 'passportBackside' });
    cropHandlerControls.birthCertificate.callback = cropHandlerCallback.bind({ fileType: 'birthCertificate' });
    cropHandlerControls.parentPassport.callback = cropHandlerCallback.bind({ fileType: 'parentPassport' });
    // pdf handler
    let [ pdfHandlerControls , setPdfHandlerControls ] = useState({ // eslint-disable-line no-unused-vars
        passport: {},
        personalIdWithAppendix: {},
        healthGreenCardOrVaccination: {},
        passportFace: {},
        passportBackside: {},
        birthCertificate: {},
        parentPassport: {}
    });

    const [ registerSubProcessOpened, setRegisterSubProcessOpened ] = useState(false);
    const [ registerSubProcessActions, setRegisterSubProcessActions ] = useState({ });
    const [ fieldsData, setFieldsData ] = useState({
        firstName: '',
        lastName: '',
        firstNameEnglish: '',
        lastNameEnglish: '',
        motherName: '',
        fatherName: '',
        gender: 'select',
        birthDate: null,
      //  cityOfBirth: '',
        countryOfBirth: (props.languageKey == 'he' ? 'Israel' : 'select'),
        passportNumber: '',
        countryOfCitizenship: (props.languageKey == 'he' ? 'Israel' : 'select'),
        //nationalIdentificationNumber: '', // not in use for now
        issuanceDate: null,
        expirationDate: null,
        issuingCountry: (props.languageKey == 'he' ? 'Israel' : 'select'),
      //  issuingCity: '',
      //  personalIdentificationNumber: '',
        purposeOfTravel: 'select',
        //borderOfEntry: 'select',
        //expirationDate: null, // not in use for now
        questionAreYouACitizenOfOtherCountry: 'no',
        currentlyCitizenOfCountries: [
            // {
            //     countryOfCitizenship: '',
            //     howCitizenshipReceived: 'select',
            //     other: '',
            //     errors: {} // errors
            // }
        ],
        questionWereYouACitizenOfOtherCountry: 'no',
        previousCitizenOfCountries: [
            // {
            //     countryOfCitizenship: '',
            //     startDate: null,
            //     endDate: null,
            //     errors: {} // errors
            // }
        ],
        visaType: '30days-single',
        visaExpeditedFlag: false,
        //deliveryType: 'messenger-d-messenger-p',
        //deliveryDestination: 'select',
        arrivalDate: null,
        //flightCountryOrigin: (props.languageKey == 'he' ? 'Israel' : 'select'),
        email: '',
        emailConfirm: '',
        // override with props data
        ...props.stage2Data
    });
    let [ fieldErrors, setFieldErrors ] = useState({ });

    // modal vars
    const [ isModalOpened, setIsModalOpened ] = useState(true);

    let FIELDS = [
        {
            key: 'lastName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_HEB_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.lastName ? ' field-error' : ''),
                    error: fieldErrors.lastName || false,
                    value: fieldsData.lastName,
                    inputProps: {
                        name: 'lastName'
                    },
                    helperText: fieldErrors.lastName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'lastName'}),
                    label: getLanguageValue('register-individual.stage2-family-name') + ' *'
                }
            }
        },
        {
            key: 'firstName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_HEB_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.firstName ? ' field-error' : ''),
                    error: fieldErrors.firstName || false,
                    value: fieldsData.firstName,
                    inputProps: {
                        name: 'firstName'
                    },
                    helperText: fieldErrors.firstName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'firstName'}),
                    label: getLanguageValue('register-individual.stage2-first-name') + ' *'
                }
            }
        },
        {
            key: 'lastNameEnglish',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.lastNameEnglish ? ' field-error' : ''),
                    error: fieldErrors.lastNameEnglish || false,
                    value: fieldsData.lastNameEnglish,
                    inputProps: {
                        name: 'lastNameEnglish'
                    },
                    helperText: fieldErrors.lastNameEnglish || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'lastNameEnglish'}),
                    label: getLanguageValue('register-individual.stage2-family-name-english') + ' *'
                }
            }
        },
        {
            key: 'firstNameEnglish',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.firstNameEnglish ? ' field-error' : ''),
                    error: fieldErrors.firstNameEnglish || false,
                    value: fieldsData.firstNameEnglish,
                    inputProps: {
                        name: 'firstNameEnglish'
                    },
                    helperText: fieldErrors.firstNameEnglish || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'firstNameEnglish'}),
                    label: getLanguageValue('register-individual.stage2-first-name-english') + ' *'
                }
            }
        },
        {
            key: 'motherName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.motherName ? ' field-error' : ''),
                    error: fieldErrors.motherName || false,
                    value: fieldsData.motherName,
                    inputProps: {
                        name: 'motherName'
                    },
                    helperText: fieldErrors.motherName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'motherName'}),
                    label: getLanguageValue('register-individual.stage2-mother-name') + ' *'
                }
            }
        },
        {
            key: 'fatherName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.fatherName ? ' field-error' : ''),
                    error: fieldErrors.fatherName || false,
                    value: fieldsData.fatherName,
                    inputProps: {
                        name: 'fatherName'
                    },
                    helperText: fieldErrors.fatherName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'fatherName'}),
                    label: getLanguageValue('register-individual.stage2-father-name') + ' *'
                }
            }
        },
        {
            key: 'gender',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'gender',
                selectLabel: getLanguageValue('register-individual.stage2-gender') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    { value: 'male', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-gender-male') },
                    { value: 'female', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-gender-female') }
                ],
                selectFormClassName: 'field-component' + (fieldErrors.gender ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.gender || false,
                    value: fieldsData.gender,
                    inputProps: {
                        name: 'gender',
                        id: 'gender'
                    },
                    helperText: fieldErrors.gender || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'birthDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#birthDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.birthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.birthDate || false,
                    autoOk: true,
                    value: fieldsData.birthDate !== '' ? fieldsData.birthDate : null,
                    inputProps: {
                        name: 'birthDate',
                        id: 'birthDate'
                    },
                    helperText: fieldErrors.birthDate || '',
                    onChange: (value) => {
                        DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'birthDate' })(value);
                        let minorGoingWithParentValue = false;
                        setIsModalOpened(true);
                        // if not part of a group
                        if (!props.isIndividualLinkedToGroup) {
                            // if date value is lower than 19 years, show minor warning
                            if (moment(value, true).isValid() && moment().diff(moment(value), 'years') < 18 && !showMinorWarning) {
                                setShowMinorWarning(true);
                            }
                        }
                        else {
                            if (moment(value, true).isValid() && moment().diff(moment(value), 'years') < 18) {
                                minorGoingWithParentValue = true;
                            }
                        }
                        setMinorGoingWithParent(minorGoingWithParentValue); // reset on change
                    },
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'birthDate'}),
                    label: getLanguageValue('register-individual.stage2-date-of-birth') + ' *'
                }
            }
        },
        {
            key: 'countryOfBirth',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'countryOfBirth',
                selectLabel: getLanguageValue('register-individual.stage2-country-of-birth') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.countryOfBirth ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.countryOfBirth || false,
                    value: fieldsData.countryOfBirth,
                    inputProps: {
                        name: 'countryOfBirth',
                        id: 'countryOfBirth'
                    },
                    helperText: fieldErrors.countryOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        /*{
            key: 'cityOfBirth',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.cityOfBirth ? ' field-error' : ''),
                    error: fieldErrors.cityOfBirth || false,
                    value: fieldsData.cityOfBirth,
                    inputProps: {
                        name: 'cityOfBirth'
                    },
                    helperText: fieldErrors.cityOfBirth || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'cityOfBirth'}),
                    label: getLanguageValue('register-individual.stage2-city-of-birth') + ' *'
                }
            }
        },*/
        {
            key: 'passportNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: props.languageKey == 'he' ? DEFAULT_NUMERIC_VALUE_VALIDATOR : DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.passportNumber ? ' field-error' : ''),
                    error: fieldErrors.passportNumber || false,
                    value: fieldsData.passportNumber,
                    inputProps: {
                        name: 'passportNumber'
                    },
                    helperText: fieldErrors.passportNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'passportNumber'}),
                    label: getLanguageValue('register-individual.stage2-passport-number') + ' *'
                }
            }
        },
        {
            key: 'countryOfCitizenship',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'countryOfCitizenship',
                selectLabel: getLanguageValue('register-individual.stage2-country-of-citizenship') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getVisaCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.countryOfCitizenship ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.countryOfCitizenship || false,
                    value: fieldsData.countryOfCitizenship,
                    inputProps: {
                        name: 'countryOfCitizenship',
                        id: 'countryOfCitizenship'
                    },
                    helperText: fieldErrors.countryOfCitizenship || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        // {
        //     key: 'nationalIdentificationNumber',
        //     props: {
        //         type: 'textfield',
        //         fieldProps: {
        //             className: 'field-component',
        //             error: false,
        //             value: fieldsData.nationalIdentificationNumber,
        //             inputProps: {
        //                 name: 'nationalIdentificationNumber'
        //             },
        //             helperText: '',
        //             onChange: DEFAULT_ON_CHANGE,
        //             label: getLanguageValue('register-individual.stage2-country-of-citizenship') + ' *'
        //         }
        //     }
        // },
        {
            key: 'issuanceDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#issuanceDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: (value) => { let currentDate = moment(moment().add('0','months').format('YYYY-MM-DD')); value = document.querySelector('#issuanceDate').value; if (moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid() && moment(value, 'DD/MM/YYYY').diff(currentDate) >= 0) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.issuanceDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.issuanceDate || false,
                    autoOk: true,
                    value: fieldsData.issuanceDate !== '' ? fieldsData.issuanceDate : null,
                    inputProps: {
                        name: 'issuanceDate',
                        id: 'issuanceDate'
                    },
                    helperText: fieldErrors.issuanceDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'issuanceDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'issuanceDate'}),
                    label: getLanguageValue('register-individual.stage2-issuance-date') + ' *'
                }
            }
        },
        {
            key: 'expirationDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#expirationDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: (value) => { let currentDate = moment(moment().add('3','months').format('YYYY-MM-DD')); value = document.querySelector('#expirationDate').value; if (moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid() && moment(value, 'DD/MM/YYYY').diff(currentDate) <= 0) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-expiration-date-must-be-above-x-months')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.expirationDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.expirationDate || false,
                    autoOk: true,
                    value: fieldsData.expirationDate !== '' ? fieldsData.expirationDate : null,
                    inputProps: {
                        name: 'expirationDate',
                        id: 'expirationDate'
                    },
                    helperText: fieldErrors.expirationDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'expirationDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'expirationDate'}),
                    label: getLanguageValue('register-individual.stage2-expiration-date') + ' *'
                }
            }
        },
        {
            key: 'issuingCountry',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'issuingCountry',
                selectLabel: getLanguageValue('register-individual.stage2-issuing-country') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.issuingCountry ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.issuingCountry || false,
                    value: fieldsData.issuingCountry,
                    inputProps: {
                        name: 'issuingCountry',
                        id: 'issuingCountry'
                    },
                    helperText: fieldErrors.issuingCountry || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
      /*  {
            key: 'issuingCity',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.issuingCity ? ' field-error' : ''),
                    error: fieldErrors.issuingCity || false,
                    value: fieldsData.issuingCity,
                    inputProps: {
                        name: 'issuingCity'
                    },
                    helperText: fieldErrors.issuingCity || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'issuingCity'}),
                    label: getLanguageValue('register-individual.stage2-issuing-city') + ' *'
                }
            }
        }
      /*  {
            key: 'personalIdentificationNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.personalIdentificationNumber ? ' field-error' : ''),
                    error: fieldErrors.personalIdentificationNumber || false,
                    value: fieldsData.personalIdentificationNumber,
                    inputProps: {
                        name: 'personalIdentificationNumber'
                    },
                    helperText: fieldErrors.personalIdentificationNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'personalIdentificationNumber'}),
                    label: getLanguageValue('register-individual.stage2-personal-identification-number') + ' *'
                }
            }
        },
        {
            key: 'flightCountryOrigin',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'flightCountryOrigin',
                selectLabel: getLanguageValue('register-individual.stage2-flight-country-origin') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.flightCountryOrigin ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.flightCountryOrigin || false,
                    value: fieldsData.flightCountryOrigin,
                    inputProps: {
                        name: 'flightCountryOrigin',
                        id: 'flightCountryOrigin'
                    },
                    helperText: fieldErrors.flightCountryOrigin || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        }*/
    ];

    // filter relevant only
    FIELDS = FIELDS.filter(item => !isInactiveField('stage2', item.key));

    if (props.languageKey != 'he') {
        // rmeove fields on non-hebrew
        FIELDS = FIELDS.filter((item) => ['firstName', 'lastName'].indexOf(item.key) == -1);
    }
    // default methods of onChange

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\"\'\.\(\)\-,\s]+)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_HEB_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FF\"\'\.\(\)\-,\s]+)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^(([0-9]+)|([a-zA-Z]+))+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    notifyClientError({ data: fieldsData, errors: { ...fieldErrors, [this.key] : validatorObj.errorMessage } });
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, date);
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    // current citizenship buttons

    const addCurrentCitizenshipButton = <div className="add-button-container">
        <Button
            disabled={!areAllCurrentCitizenshipValuesOk()}
            onClick={() => {
            fieldsData.currentlyCitizenOfCountries.push({
                countryOfCitizenship: props.languageKey == 'he' ? 'Israel' : 'select',
                howCitizenshipReceived: 'select',
                other: ''
            });
            setFieldsData({ ...fieldsData });
        }}>
            <AddCircleIcon />{getLanguageValue('register-individual.stage2-add-another')}
        </Button>
    </div>;

    const deleteCurrentCitizenshipButton = (props) => (<div className="delete-button-container">
        <Button onClick={(e) => {
            if (props.itemIndex != null) {
                fieldsData.currentlyCitizenOfCountries.splice(props.itemIndex, 1);
                setFieldsData({ ...fieldsData });
            }
        }}>
            <RemoveCircleIcon />{getLanguageValue('register-individual.stage2-remove')}
        </Button>
    </div>);

    // previous citizenship buttons
    const addPreviousCitizenshipButton = <div className="add-button-container">
        <Button
            disabled={!areAllPreviousCitizenshipValuesOk()}
            onClick={() => {
                fieldsData.previousCitizenOfCountries.push({
                    countryOfCitizenship: 'Israel',
                    startDate: null,
                    endDate: null
                });
                setFieldsData({ ...fieldsData });
            }}>
            <AddCircleIcon />{getLanguageValue('register-individual.stage2-add-another')}
        </Button>
    </div>;

    const deletePreviousCitizenshipButton = (props) => (<div className="delete-button-container">
        <Button onClick={(e) => {
            if (props.itemIndex != null) {
                fieldsData.previousCitizenOfCountries.splice(props.itemIndex, 1);
                setFieldsData({ ...fieldsData });
            }
        }}>
            <RemoveCircleIcon />{getLanguageValue('register-individual.stage2-remove')}
        </Button>
    </div>);

    useEffect(() => {
        actions.setActiveStage(2);
        // make sure there's at least one item
        let shouldUpdateFieldsData = false;
        if (!fieldsData.currentlyCitizenOfCountries.length) {
            shouldUpdateFieldsData = true;
            fieldsData.currentlyCitizenOfCountries.push({
                countryOfCitizenship: 'Israel',
                howCitizenshipReceived: 'select',
                other: ''
            });
        }
        if (!fieldsData.previousCitizenOfCountries.length) {
            shouldUpdateFieldsData = true;
            fieldsData.previousCitizenOfCountries.push({
                countryOfCitizenship: 'Israel',
                startDate: null,
                endDate: null
            });
        }
        if (shouldUpdateFieldsData) {
            setFieldsData(fieldsData);
        }
        // in case of minor in existing form, automatically setup minor going with parent
        if (props.individualKey) {
            if (moment().diff(moment(fieldsData.birthDate), 'years') < 18) {
                setMinorGoingWithParent(true);
            }
        }
        if (getQueryParam('focusInto')) {
            setTimeout(() => {
                if (document.querySelector('#' + getQueryParam('focusInto'))) {
                    document.querySelector('#' + getQueryParam('focusInto')).scrollIntoView();
                }
            }, 500);
        }
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    return <RegisterStage2Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage2-enter-applicant-information')}</h1>
            <div className="form-information-container">
                <span className="bold-text important-text">{getLanguageValue('register-individual.stage2-provide-responses-in-language')}</span>
                <br/>
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage2-required-fields-with-asterisk')}</span>
            </div>
            <h2>
                {getLanguageValue('register-individual.stage2-applicant-and-passport-information')}
            </h2>
            <Grid container className={"upload-passport-and-form-fields-container"} spacing={3} >
                <Grid item className={"form-fields-container"} md={8}>
                    <Grid container className={"fields-container"}>

                        {FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={6}>
                            <GenericFieldComponent {...fieldItem.props} />
                        </Grid>))}
                    </Grid>
                </Grid>
            </Grid>
            {!areImagesInactive() && <div>
                <h2>
                    {getLanguageValue('register-individual.stage2-upload-passport-pictures')}
                </h2>
                <Grid container>
                    <Grid item className={"upload-passport-container"}>
                        <div className={"upload-general-warning-message"} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-general-warning') }} />
                        <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'passport.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#passport-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-passport') + ' *'}</Button>
                        <input type="file" className={'hidden-file-field'} id="passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passport', '.upload-passport-container'); }} />
                        <br/>
                        {lodashGet(fileUploadsData, 'passport.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'passport.error')}</div> : null}
                        <div><br/><img alt="" id={'uploaded-image-passport'} className={"uploaded-image passport" + (lodashGet(fileUploadsData, 'passport.base64') && !lodashGet(fileUploadsData, 'passport.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'passport.base64')} /></div>
                        {lodashGet(fileUploadsData, 'passport.base64') && !lodashGet(fileUploadsData, 'passport.editMode') && !lodashGet(fileUploadsData, 'passport.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'passport.editMode', true); cropHandlerControls.passport.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                        <div><CropHandler controls={cropHandlerControls.passport} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportDesktop']} imageCssSelector={'.uploaded-image.passport'} imageSrc={lodashGet(fileUploadsData, 'passport.base64')} /></div>
                        {lodashGet(fileUploadsData, 'passport.showAsPdf') && <>
                            <PdfHandler base64Data={lodashGet(fileUploadsData, 'passport.base64')} className={'passport'} controls={pdfHandlerControls.passport} />
                        </>}
                        <br/><br/>
                    </Grid>
                </Grid>
                <Grid container className={"sample-image-container"}>
                    <Grid item>
                        <img alt="" className={"sample-image passport"} src={getContextRoot() + 'images/sample_passport.jpg'} />
                    </Grid>
                </Grid>
                <br/>
                {isPassportBacksideRequired() && <>
                    <br/>
                    <Grid container className={"upload-passport-backside-container"}>
                        <Grid item className={"upload-passport-backside-button-container"}>
                            <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'passportBackside.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#passport-backside-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-passport-backside')  + ' *'}</Button>
                            <input type="file" className={'hidden-file-field'} id="passport-backside-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passportBackside', '.upload-passport-backside-image-container'); }} />
                        </Grid>
                    </Grid>
                    <Grid container className={"upload-passport-backside-image-container"}>
                        <Grid item>
                            {lodashGet(fileUploadsData, 'passportBackside.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'passportBackside.error')}</div> : null}
                            <div><br/><img alt="" id={'uploaded-image-passport-backside'} className={"uploaded-image passport-backside" + (lodashGet(fileUploadsData, 'passportBackside.base64') && !lodashGet(fileUploadsData, 'passportBackside.showAsPdf')  ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'passportBackside.base64')} /></div>
                            {lodashGet(fileUploadsData, 'passportBackside.base64') && !lodashGet(fileUploadsData, 'passportBackside.editMode') && !lodashGet(fileUploadsData, 'passportBackside.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'passportBackside.editMode', true); cropHandlerControls.passportBackside.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                            <CropHandler controls={cropHandlerControls.passportBackside} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportDesktop']} imageCssSelector={'.uploaded-image.passport-backside'} imageSrc={lodashGet(fileUploadsData, 'passportBackside.base64')} />
                            {lodashGet(fileUploadsData, 'passportBackside.showAsPdf') && <>
                                <PdfHandler base64Data={lodashGet(fileUploadsData, 'passportBackside.base64')} className={'passportBackside'} controls={pdfHandlerControls.passportBackside} />
                            </>}
                        </Grid>
                    </Grid>
                    <Grid container className={"sample-image-container"}>
                        <Grid item>
                            <img alt="" className={"sample-image passport-backside"} src={getContextRoot() + 'images/sample_passport_backside.jpg'} />
                        </Grid>
                    </Grid>
                    <br/></>}
                {isImageRequired('passportFace') && <><div className={"upload-general-warning-message"} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-passport-face-note')}} />
                    <Grid container className={"upload-passport-face-container"}>
                        <Grid item className={"upload-passport-face-button-container"}>
                            <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'passportFace.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#passport-face-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-passport-face') + ' *'}</Button>
                            <input type="file" className={'hidden-file-field'} id="passport-face-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passportFace', '.upload-passport-face-image-container'); }} />
                        </Grid>
                    </Grid>
                    <Grid container className={"upload-passport-face-image-container"}>
                        <Grid item>
                            {lodashGet(fileUploadsData, 'passportFace.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'passportFace.error')}</div> : null}
                            <div><br/><img alt="" id={'uploaded-image-passport-face'} className={"uploaded-image passport-face" + (lodashGet(fileUploadsData, 'passportFace.base64') && !lodashGet(fileUploadsData, 'passportFace.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'passportFace.base64')} /></div>
                            {lodashGet(fileUploadsData, 'passportFace.base64') && !lodashGet(fileUploadsData, 'passportFace.editMode') && !lodashGet(fileUploadsData, 'passportFace.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'passportFace.editMode', true); cropHandlerControls.passportFace.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                            <CropHandler controls={cropHandlerControls.passportFace} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['passportFaceMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['passportFaceDesktop']} imageCssSelector={'.uploaded-image.passport-face'} imageSrc={lodashGet(fileUploadsData, 'passportFace.base64')} />
                            {lodashGet(fileUploadsData, 'passportFace.showAsPdf') && <>
                                <PdfHandler base64Data={lodashGet(fileUploadsData, 'passportFace.base64')} className={'passportFace'} controls={pdfHandlerControls.passportFace} />
                            </>}
                        </Grid>
                    </Grid>
                    <Grid container className={"sample-image-container"}>
                        <Grid item>
                            <img alt="" className={"sample-passport-face-image"} src={getContextRoot() + 'images/sample_passport_face.jpg'} />
                        </Grid>
                    </Grid>
                </>}
                {isImageRequired('personalIdWithAppendix') && <>
                    <Grid container className={"upload-personal-id-with-appendix-container"}>
                        <Grid item className={"upload-personal-id-with-appendix-button-container"}>
                            <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'personalIdWithAppendix.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#personal-id-with-appendix-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-personal-id-with-appendix') + ' *'}</Button>
                            <input type="file" className={'hidden-file-field'} id="personal-id-with-appendix-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'personalIdWithAppendix', '.upload-personal-id-with-appendix-image-container'); }} />
                        </Grid>
                    </Grid>
                    <Grid container className={"upload-personal-id-with-appendix-image-container"}>
                        <Grid item>
                            {lodashGet(fileUploadsData, 'personalIdWithAppendix.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'personalIdWithAppendix.error')}</div> : null}
                            <div><br/><img alt="" id={'uploaded-image-personal-id-with-appendix'} className={"uploaded-image personal-id-with-appendix" + (lodashGet(fileUploadsData, 'personalIdWithAppendix.base64') && !lodashGet(fileUploadsData, 'personalIdWithAppendix.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'personalIdWithAppendix.base64')} /></div>
                            {lodashGet(fileUploadsData, 'personalIdWithAppendix.base64') && !lodashGet(fileUploadsData, 'personalIdWithAppendix.editMode') && !lodashGet(fileUploadsData, 'personalIdWithAppendix.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'personalIdWithAppendix.editMode', true); cropHandlerControls.personalIdWithAppendix.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                            <CropHandler controls={cropHandlerControls.personalIdWithAppendix} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['personalIdWithAppendixMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['personalIdWithAppendixDesktop']} imageCssSelector={'.uploaded-image.personal-id-with-appendix'} imageSrc={lodashGet(fileUploadsData, 'personalIdWithAppendix.base64')} />
                            {lodashGet(fileUploadsData, 'personalIdWithAppendix.showAsPdf') && <>
                                <PdfHandler base64Data={lodashGet(fileUploadsData, 'personalIdWithAppendix.base64')} className={'personalIdWithAppendix'} controls={pdfHandlerControls.personalIdWithAppendix} />
                            </>}
                        </Grid>
                    </Grid>
                    <Grid container className={"sample-image-container"}>
                        <Grid item>
                            <img alt="" className={"sample-image personal-id-with-appendix"} src={getContextRoot() + 'images/sample_personal_id.jpg'} />
                        </Grid>
                    </Grid>
                </>}

                {isImageRequired('healthGreenCardOrVaccination') && <>
                    <Grid container className={"upload-health-green-card-or-vaccination-container"}>
                        <Grid item className={"upload-health-green-card-or-vaccination-button-container"}>
                            <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#health-green-card-or-vaccination-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-health-green-card-or-vaccination') + ' *'}</Button>
                            <input type="file" className={'hidden-file-field'} id="health-green-card-or-vaccination-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'healthGreenCardOrVaccination', '.upload-health-green-card-or-vaccination-image-container'); }} />
                        </Grid>
                    </Grid>
                    <Grid container className={"upload-health-green-card-or-vaccination-image-container"}>
                        <Grid item>
                            {lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.error')}</div> : null}
                            <div><br/><img alt="" id={'uploaded-image-health-green-card-or-vaccination'} className={"uploaded-image health-green-card-or-vaccination" + (lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64') && !lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64')} /></div>
                            {lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64') && !lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.editMode') && !lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'healthGreenCardOrVaccination.editMode', true); cropHandlerControls.healthGreenCardOrVaccination.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                            <CropHandler controls={cropHandlerControls.healthGreenCardOrVaccination} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['healthGreenCardOrVaccinationMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['healthGreenCardOrVaccinationDesktop']} imageCssSelector={'.uploaded-image.health-green-card-or-vaccination'} imageSrc={lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64')} />
                            {lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.showAsPdf') && <>
                                <PdfHandler base64Data={lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64')} className={'healthGreenCardOrVaccination'} controls={pdfHandlerControls.healthGreenCardOrVaccination} />
                            </>}
                        </Grid>
                    </Grid>
                    <Grid container className={"sample-image-container"}>
                        <Grid item>
                            <img alt="" className={"sample-image health-green-card-or-vaccination"} src={getContextRoot() + 'images/sample_vaccination.jpg'} />
                        </Grid>
                    </Grid>
                </>}

                {minorGoingWithParent && <>
                    <br/>
                    {CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('birthCertificate') > -1 && <>
                        <div className={"upload-general-warning-message"} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-upload-birth-certificate-note')}} />
                        <Grid container className={"upload-birth-certificate-image-container"}>
                            <Grid item className={"upload-birth-certificate-button-container"}>
                                <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'birthCertificate.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#birth-certificate-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-birth-certificate') + ' *'}</Button>
                                <input type="file" className={'hidden-file-field'} id="birth-certificate-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'birthCertificate', '.upload-birth-certificate-image-container'); }} />
                                <br/>
                                {lodashGet(fileUploadsData, 'birthCertificate.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'birthCertificate.error')}</div> : null}
                                <div><br/><img alt="" id={'uploaded-image-birth-certificate'} className={"uploaded-image birth-certificate" + (lodashGet(fileUploadsData, 'birthCertificate.base64') && !lodashGet(fileUploadsData, 'birthCertificate.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'birthCertificate.base64')} /></div>
                                {lodashGet(fileUploadsData, 'birthCertificate.base64') && !lodashGet(fileUploadsData, 'birthCertificate.editMode') && !lodashGet(fileUploadsData, 'birthCertificate.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'birthCertificate.editMode', true); cropHandlerControls.birthCertificate.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                                <CropHandler controls={cropHandlerControls.birthCertificate} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['birthCertificateMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['birthCertificateDesktop']} imageCssSelector={'.uploaded-image.birth-certificate'} imageSrc={lodashGet(fileUploadsData, 'birthCertificate.base64')} />
                                {lodashGet(fileUploadsData, 'birthCertificate.showAsPdf') && <>
                                    <PdfHandler base64Data={lodashGet(fileUploadsData, 'birthCertificate.base64')} className={'birthCertificate'} controls={pdfHandlerControls.birthCertificate} />
                                </>}
                            </Grid>
                        </Grid>
                        <Grid container className={"sample-image-container"}>
                            <Grid item>
                                <img alt="" className={"sample-image birth-certificate"} src={getContextRoot() + 'images/sample_birth_certificate.jpg'} />
                            </Grid>
                        </Grid>
                    </>}
                    <br/>
                    {CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('parentPassport') > -1 && <>
                        <Grid container className={"upload-parent-passport-image-container"}>
                            <Grid item className={"upload-parent-passport-button-container"}>
                                <Button className={"file-upload-button" + (lodashGet(fileUploadsData, 'parentPassport.error') ? ' field-error' : '')} onClick={() => { document.querySelector('#parent-passport-file-upload-field').click(); }}>{getLanguageValue('register-individual.stage2-upload-parent-passport') + ' *'}</Button>
                                <input type="file" className={'hidden-file-field'} id="parent-passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'parentPassport', '.upload-parent-passport-image-container'); }} />
                                <br/>
                                {lodashGet(fileUploadsData, 'parentPassport.error') ? <div className={"file-upload-error"}>{lodashGet(fileUploadsData, 'parentPassport.error')}</div> : null}
                                <div><br/><img alt="" id={'uploaded-image-parent-passport'} className={"uploaded-image parent-passport" + (lodashGet(fileUploadsData, 'parentPassport.base64') && !lodashGet(fileUploadsData, 'parentPassport.showAsPdf') ? '' : ' hidden')} src={lodashGet(fileUploadsData, 'parentPassport.base64')} /></div>
                                {lodashGet(fileUploadsData, 'parentPassport.base64') && !lodashGet(fileUploadsData, 'parentPassport.editMode') && !lodashGet(fileUploadsData, 'parentPassport.showAsPdf') ? <div><Button className={"file-upload-edit-button"} onClick={() => { lodashSet(fileUploadsData, 'parentPassport.editMode', true); cropHandlerControls.parentPassport.restart(); setFileUploadsData({ ...fileUploadsData }); }}>{getLanguageValue('crop-handler-edit')}</Button></div> : null}
                                <CropHandler controls={cropHandlerControls.parentPassport} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS['parentPassportMobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS['parentPassportDesktop']} imageCssSelector={'.uploaded-image.parent-passport'} imageSrc={lodashGet(fileUploadsData, 'parentPassport.base64')} />
                                {lodashGet(fileUploadsData, 'parentPassport.showAsPdf') && <>
                                    <PdfHandler base64Data={lodashGet(fileUploadsData, 'parentPassport.base64')} className={'parentPassport'} controls={pdfHandlerControls.parentPassport} />
                                </>}
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container className={"sample-image-container"}>
                            <Grid item>
                                <img alt="" className={"sample-image parent-passport"} src={getContextRoot() + 'images/sample_passport.jpg'} />
                            </Grid>
                        </Grid>
                    </>}
                </>}
            </div>}
            <div>
                <h2>
                    {getLanguageValue('register-individual.stage2-select-visa-type')}
                </h2>
                <Grid container>
                    <Grid item className={"select-visa-type-container"} md={4}>
                        <GenericFieldComponent
                            type={'select'}
                            selectId={'visaType'}
                            selectLabel={getLanguageValue('register-individual.stage2-select-visa-type') + ' *'}
                            selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                { value: '30days-single', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-select-visa-type-30days-single') + ' (' + getLanguageValue('register-individual.stage2-select-visa-type-30days-single-price') + ')' },
                                { value: '30days-multi', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-select-visa-type-30days-multi') + ' (' + getLanguageValue('register-individual.stage2-select-visa-type-30days-multi-price') + ')' },
                                { value: '90days-single', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-select-visa-type-90days-single') + ' (' + getLanguageValue('register-individual.stage2-select-visa-type-90days-single-price') + ')' },
                                { value: '90days-multi', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-select-visa-type-90days-multi') + ' (' + getLanguageValue('register-individual.stage2-select-visa-type-90days-multi-price') + ')' }
                            ]}
                            selectFormClassName={'field-component' + (fieldErrors.visaType ? ' field-error' : '')}
                            fieldProps={{
                                native: true,
                                error: fieldErrors.visaType,
                                value: fieldsData.visaType,
                                inputProps: {
                                    name: 'visaType',
                                    id: 'visaType'
                                },
                                helperText: fieldErrors.visaType || '',
                                onChange: (e) => {
                                    setFieldsData({...fieldsData, [e.target.name] : e.target.value, visaExpeditedFlag: false });
                                    setFieldErrors({ ...fieldErrors, [e.target.name] : null });
                                    if (fieldsData.arrivalDate) {
                                        if (isArrivalDateWithVisaTypeInvalid(null, e.target.value)) {
                                            setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-individual-stage2-arrival-date-too-early') });
                                        }
                                        else {
                                            setFieldErrors({...fieldErrors, arrivalDate: false });
                                        }
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                {false &&<Grid container className={'visa-type-warning-container'}>
                    <Grid item>
                        {getLanguageValue('register-individual.stage2-select-visa-type-warning-' + fieldsData.visaType)}
                    </Grid>
                </Grid>}
                <Grid container>
                    <Grid item className={"arrival-date-container"} md={4}>
                        <GenericFieldComponent
                            type={"date"}

                            fieldProps={{

                                className: 'field-component arrival-date' + (fieldErrors.arrivalDate ? ' field-error' : ''),
                                variant: 'inline',
                                format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                                error: fieldErrors.arrivalDate ? true : false,
                                autoOk: true,
                                value: fieldsData.arrivalDate,
                                inputProps: {
                                    name: 'arrivalDate',
                                    id: 'arrivalDate'
                                },
                                helperText: fieldErrors.arrivalDate || '',
                                onChange: (value) => {
                                    DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'arrivalDate' })(value);
                                    if (moment(value, true).isValid() && moment(value).diff(moment(moment().format('YYYY-MM-DD')), 'days') < 0) {
                                        setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-individual.stage2-date-cannot-be-before-today') });
                                    }
                                    else if (moment(value, true).isValid() && moment(value).diff(moment(), 'months') > 6) {
                                        setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-individual-stage2-arrival-date-too-late') });
                                    }
                                    else if (fieldsData.visaType != 'select' && moment(value, true).isValid() && moment(value).diff(moment(moment().format('YYYY-MM-DD')), 'days') >= 0) {
                                        if (isArrivalDateWithVisaTypeInvalid(value, null)) {
                                            setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-individual-stage2-arrival-date-too-early') });
                                        }
                                        else {
                                            setFieldErrors({...fieldErrors, arrivalDate: false });
                                        }
                                    }
                                },
                                onBlur: () => {
                                    let value = document.querySelector('#arrivalDate').value;
                                    if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) {
                                        notifyClientError({ data: fieldsData, errors: {...fieldErrors, arrivalDate: getLanguageValue('register-field-errors-invalid') } });
                                        setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-field-errors-invalid') });
                                    }
                                    else if (!value) {
                                        notifyClientError({ data: fieldsData, errors: {...fieldErrors, arrivalDate: getLanguageValue('register-field-errors-empty') } });
                                        setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-field-errors-empty') });
                                    }
                                },
                                label: getLanguageValue('register-individual.stage2-arrival-date') + ' *'
                            }}
                        />
                        <div className={'arrival-date-business-days-note'}>
                            {getLanguageValue('register-individual.stage2-arrival-date-business-days-note')}
                        </div>
                    </Grid>
                </Grid>
                {!isInactiveField('stage2', 'visaExpedited') && <Grid container>
                    <Grid item className={"expedited-visa-container"} md={4}>
                        <FormControlLabel
                            className={"expedited-visa-component"}
                            control={
                                <Checkbox
                                    checked={fieldsData.visaExpeditedFlag}
                                    onChange={(e) => { setFieldsData({...fieldsData, visaExpeditedFlag: e.target.checked }); }}
                                    name="expeditedVisa"
                                    color="primary"
                                />
                            }
                            label={getLanguageValue('register-individual.stage2-expedite-visa')}
                        />
                    </Grid>
                </Grid>}
                {/*!props.isIndividualLinkedToGroup && <>
                    <h2>
                        {getLanguageValue('register-individual.stage2-select-visa-delivery-type')}
                    </h2>
                    <div className={'select-visa-delivery-explanation-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-select-visa-delivery-explanation') }} />
                    <Grid container>
                        <Grid item className={"select-visa-delivery-type-container"} md={4}>
                            <FormControl component="fieldset">
                                <RadioGroup name="deliveryTypeRadio" value={fieldsData.deliveryType} onChange={(e) => { fieldErrors.deliveryType = false; fieldsData.deliveryType = e.target.value; setFieldsData({...fieldsData}); }}>
                                    {['messenger-d-messenger-p', 'messenger-d-self-p', 'self-d-self-p' ].map((item) => ( <FormControlLabel
                                        classes={{ root: 'delivery-type-label-control-root-component', label: 'delivery-type-label-control-label-component' + (fieldErrors.deliveryType ? ' field-error' : '') }}
                                        value={item}
                                        control={<Radio
                                            classes={{ root: 'delivery-type-radio-root' + (fieldErrors.deliveryType ? ' field-error' : '') }}
                                            color="primary"
                                        />}
                                        label={getLanguageValue('register-individual.stage2-select-visa-delivery-type-' + item)}
                                    />))}
                                </RadioGroup>
                            </FormControl>
                            {fieldsData.deliveryType != 'messenger-d-messenger-p' && <div className={'delivery-self-address-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-select-visa-delivery-self-address') }} />}
                            {fieldsData.deliveryType != 'self-d-self-p' && <div className={'delivery-self-address-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-select-visa-delivery-activity-time') }} />}
                            {['messenger-d-self-p', 'messenger-d-messenger-p'].indexOf(fieldsData.deliveryType) > -1 && <div className={'visa-delivery-destination-container'}>
                                <GenericFieldComponent
                                    type={'select'}
                                    selectId={'deliveryDestination'}
                                    selectLabel={getLanguageValue('register-individual.stage2-select-visa-delivery-destination') + ' *'}
                                    selectOptions={[
                                        { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                        ...(CONSTANTS?.PRICES?.delivery ? CONSTANTS?.PRICES?.delivery.map((item) => ({ value: item.key, isDisabled: false, displayText: item.displayText + ` (${CONSTANTS.DOLLAR}` + (fieldsData.deliveryType == 'messenger-d-self-p' ? `${item.value}` : `${item.value*2}`) + `)` })) : []),
                                    ]}
                                    selectFormClassName={'field-component' + (fieldErrors.deliveryDestination ? ' field-error' : '')}
                                    fieldProps={{
                                        native: true,
                                        error: fieldErrors.deliveryDestination,
                                        value: fieldsData.deliveryDestination,
                                        inputProps: {
                                            name: 'deliveryDestination',
                                            id: 'deliveryDestination'
                                        },
                                        helperText: fieldErrors.deliveryDestination || '',
                                        onChange: (e) => {
                                            setFieldsData({...fieldsData, [e.target.name] : e.target.value });
                                            setFieldErrors({ ...fieldErrors, [e.target.name] : null });
                                        }
                                    }}
                                />
                            </div>}
                        </Grid>
                    </Grid>
                </>*/}
                <h2>{getLanguageValue('register-individual.stage2-select-purpose-of-travel')}</h2>
                <GenericFieldComponent
                    type={'select'}
                    selectId={'purposeOfTravel'}
                    selectLabel={getLanguageValue('register-individual.stage2-select-purpose-of-travel') + ' *'}
                    selectOptions={[
                        { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                        ...(getPurposeOfTravelList().map((item) => ({ value: item.key, isDisabled: false, displayText: item.value }))),
                    ]}
                    selectFormClassName={'field-component purpose-of-travel' + (fieldErrors.purposeOfTravel ? ' field-error' : '')}
                    fieldProps={{
                        native: true,
                        error: fieldErrors.purposeOfTravel,
                        value: fieldsData.purposeOfTravel,
                        inputProps: {
                            name: 'purposeOfTravel',
                            id: 'purposeOfTravel'
                        },
                        helperText: fieldErrors.purposeOfTravel || '',
                        onChange: (e) => {
                            setFieldsData({...fieldsData, [e.target.name] : e.target.value });
                            setFieldErrors({ ...fieldErrors, [e.target.name] : null });
                        }
                    }}
                />
                {/*}<h2>{getLanguageValue('register-individual.stage2-select-border-of-entry')}</h2>
                <GenericFieldComponent
                    type={'select'}
                    selectId={'borderOfEntry'}
                    selectLabel={getLanguageValue('register-individual.stage2-select-border-of-entry') + ' *'}
                    selectOptions={[
                        { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                        ...(getBorderOfEntryList().map((item) => ({ value: item.key, isDisabled: false, displayText: item.value }))),
                    ]}
                    selectFormClassName={'field-component border-of-entry' + (fieldErrors.borderOfEntry ? ' field-error' : '')}
                    fieldProps={{
                        native: true,
                        error: fieldErrors.borderOfEntry,
                        value: fieldsData.borderOfEntry,
                        inputProps: {
                            name: 'borderOfEntry',
                            id: 'borderOfEntry'
                        },
                        helperText: fieldErrors.borderOfEntry || '',
                        onChange: (e) => {
                            setFieldsData({...fieldsData, [e.target.name] : e.target.value });
                            setFieldErrors({ ...fieldErrors, [e.target.name] : null });
                        }
                    }}
                />*/}
            </div>
            <div className={"other-country-citizenship-container"}>
                {false && <h2>{ getLanguageValue('register-individual.stage2-other-citizenship-or-nationality') }</h2>}
                {false && <div className={"is-citizen-question-container"}>
                    <Grid container spacing={3}>
                        <Grid className={"question-label-container"} item>
                            <label htmlFor={"questionAreYouACitizenOfOtherCountry"}>{ getLanguageValue('register-individual.stage2-are-you-a-citizen-of-any-other-country') + ' *'}</label>
                        </Grid>
                        <Grid item>
                            <GenericFieldComponent
                                type={"select"}
                                selectLabel={''}
                                selectOptions={[
                                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                    { value: 'yes', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-yes') },
                                    { value: 'no', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-no') }
                                ]}
                                selectFormClassName={'field-component no-top-label' + (fieldErrors.questionAreYouACitizenOfOtherCountry ? ' field-error' : '')}
                                fieldProps={{

                                    native: true,
                                    error: fieldErrors.questionAreYouACitizenOfOtherCountry,
                                    value: fieldsData.questionAreYouACitizenOfOtherCountry,
                                    inputProps: {
                                        name: 'questionAreYouACitizenOfOtherCountry',
                                        id: 'questionAreYouACitizenOfOtherCountry'
                                    },
                                    helperText: fieldErrors.questionAreYouACitizenOfOtherCountry || '',
                                    onChange: DEFAULT_ON_CHANGE
                                }}
                            />
                        </Grid>
                    </Grid>
                    {fieldsData.questionAreYouACitizenOfOtherCountry === 'yes' && <><div className={'other-countries-citizenship-list-container'}>
                        {fieldsData.currentlyCitizenOfCountries.map(function(citizenshipCountryItem, citizenshipCountryItemIndex) {
                            return <Grid container className={'other-countries-citizenship-list-item-row' + (citizenshipCountryItemIndex % 2 == 0 ? ' even' : ' odd')} key={citizenshipCountryItemIndex}>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"select"}
                                        selectId={'countryOfCitizenship' + citizenshipCountryItemIndex}
                                        selectLabel={getLanguageValue('register-individual.stage2-country-of-citizenship-or-nationality') + ' *'}
                                        selectOptions={[
                                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                                ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                                        ]}
                                        selectFormClassName={'field-component' + (lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship') ? ' field-error' : '')}
                                        fieldProps={{
                                            native: true,
                                            error: lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship'),
                                            value: citizenshipCountryItem.countryOfCitizenship,
                                            inputProps: {
                                                name: 'countryOfCitizenship'
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship') || '',
                                            onChange: (e) => { fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex], countryOfCitizenship: e.target.value, errors: { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex].errors, countryOfCitizenship: false } }; setFieldsData({...fieldsData }); }
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"select"}
                                        selectLabel={getLanguageValue('register-individual.stage2-how-citizenship-received') + ' *'}
                                        selectId={'howCitizenshipReceived' + citizenshipCountryItemIndex}
                                        selectOptions={[
                                            { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                            { value: 'by-birth', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-citizenship-received-by-birth') },
                                            { value: 'by-parents', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-citizenship-received-through-parents') },
                                            { value: 'naturalized', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-citizenship-received-naturalized') },
                                            { value: 'other', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-citizenship-received-other') }
                                        ]}
                                        selectFormClassName={'field-component' + (lodashGet(citizenshipCountryItem, 'errors.howCitizenshipReceived') ? ' field-error' : '')}
                                        fieldProps={{
                                            className: 'field-component',
                                            native: true,
                                            error: lodashGet(citizenshipCountryItem, 'errors.howCitizenshipReceived'),
                                            value: citizenshipCountryItem.howCitizenshipReceived,
                                            inputProps: {
                                                name: 'howCitizenshipReceived',
                                                id: 'howCitizenshipReceived' + citizenshipCountryItemIndex
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.howCitizenshipReceived') || '',
                                            onChange: (e) => { fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex], howCitizenshipReceived: e.target.value, other: '', errors: { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex].errors, howCitizenshipReceived: false, other: false } }; setFieldsData({...fieldsData }); }
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"textfield"}
                                        fieldProps={{
                                            className: 'field-component' + (lodashGet(citizenshipCountryItem, 'errors.other') ? ' field-error' : ''),
                                            error: lodashGet(citizenshipCountryItem, 'errors.other'),
                                            value: citizenshipCountryItem.other,
                                            disabled: citizenshipCountryItem.howCitizenshipReceived !== 'other',
                                            inputProps: {
                                                name: 'other'
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.other') || '',
                                            onChange: (e) => { fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex], other: e.target.value, errors: { ...fieldsData.currentlyCitizenOfCountries[citizenshipCountryItemIndex].errors, other: false } }; setFieldsData({...fieldsData }); },
                                            label: getLanguageValue('register-individual.stage2-citizenship-received-other') + (citizenshipCountryItem.howCitizenshipReceived !== 'other' ? '' : ' *')
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    {getDisplayedActionForCurrentCitizenship(citizenshipCountryItem, citizenshipCountryItemIndex)}
                                </Grid>
                            </Grid>;
                        })}
                    </div>
                    {fieldsData.currentlyCitizenOfCountries.length > 1 && <div>{addCurrentCitizenshipButton}</div>}
                    </>}
                </div>}

                {false && <div className={"was-citizen-question-container"}>
                    <Grid container spacing={3}>
                        <Grid className={"question-label-container"} item>
                            <label htmlFor={"questionWereYouACitizenOfOtherCountry"}>{ getLanguageValue('register-individual.stage2-have-you-been-a-citizen-of-any-other-country') + ' *' }</label>
                        </Grid>
                        <Grid item>
                            <GenericFieldComponent
                                type={"select"}
                                selectLabel={''}
                                selectOptions={[
                                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                    { value: 'yes', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-yes') },
                                    { value: 'no', isDisabled: false, displayText: getLanguageValue('register-individual.stage2-no') }
                                ]}
                                selectFormClassName={'field-component no-top-label' + (fieldErrors.questionWereYouACitizenOfOtherCountry ? ' field-error' : '')}
                                fieldProps={{

                                    native: true,
                                    error: fieldErrors.questionWereYouACitizenOfOtherCountry,
                                    value: fieldsData.questionWereYouACitizenOfOtherCountry,
                                    inputProps: {
                                        name: 'questionWereYouACitizenOfOtherCountry',
                                        id: 'questionWereYouACitizenOfOtherCountry'
                                    },
                                    helperText: fieldErrors.questionWereYouACitizenOfOtherCountry || '',
                                    onChange: DEFAULT_ON_CHANGE
                                }}
                            />
                        </Grid>
                    </Grid>

                    {fieldsData.questionWereYouACitizenOfOtherCountry === 'yes' && <><div className={'other-countries-citizenship-list-container'}>
                        {fieldsData.previousCitizenOfCountries.map(function(citizenshipCountryItem, citizenshipCountryItemIndex) {
                            return <Grid container className={'other-countries-citizenship-list-item-row' + (citizenshipCountryItemIndex % 2 == 0 ? ' even' : ' odd')} key={citizenshipCountryItemIndex}>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"select"}
                                        selectId={'previousCountryOfCitizenship' + citizenshipCountryItemIndex}
                                        selectLabel={getLanguageValue('register-individual.stage2-country-of-citizenship-or-nationality') + ' *'}
                                        selectOptions={[
                                            { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                            ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                                        ]}
                                        selectFormClassName={'field-component' + (lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship') ? ' field-error' : '')}
                                        fieldProps={{
                                            native: true,
                                            className: 'field-component' + (lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship') ? ' field-error' : ''),
                                            error: lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship'),
                                            value: citizenshipCountryItem.countryOfCitizenship,
                                            inputProps: {
                                                name: 'countryOfCitizenship'
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.countryOfCitizenship') || '',
                                            onChange: (e) => { fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex], countryOfCitizenship: e.target.value, errors: { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex].errors, countryOfCitizenship: false } }; setFieldsData({...fieldsData }); },
                                            label: getLanguageValue('register-individual.stage2-country-of-citizenship-or-nationality') + ' *'
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"date"}

                                        fieldProps={{

                                            className: 'field-component citizenship-start-date' + (lodashGet(citizenshipCountryItem, 'errors.startDate') ? ' field-error' : ''),
                                            variant: 'inline',
                                            format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                                            error: lodashGet(citizenshipCountryItem, 'errors.startDate'),
                                            autoOk: true,
                                            value: citizenshipCountryItem.startDate,
                                            inputProps: {
                                                name: 'citizenshipStartDate',
                                                id: 'citizenshipStartDate' + citizenshipCountryItemIndex
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.startDate') || '',
                                            onChange: (date) => {
                                                if (moment(date, true).isValid()) {
                                                    fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex], startDate: date, errors: { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex].errors, startDate: false } };
                                                    setFieldsData({ ...fieldsData });
                                                }
                                                else {
                                                    fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex], startDate: null, errors: { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex].errors, startDate: false } };
                                                    setFieldsData({...fieldsData });
                                                }
                                            },
                                            label: getLanguageValue('register-individual.stage2-citizenship-start-date') + ' *'
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    <GenericFieldComponent
                                        type={"date"}

                                        fieldProps={{

                                            className: 'field-component citizenship-end-date' + (lodashGet(citizenshipCountryItem, 'errors.endDate') ? ' field-error' : ''),
                                            variant: 'inline',
                                            format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                                            error: lodashGet(citizenshipCountryItem, 'errors.endDate'),
                                            autoOk: true,
                                            value: citizenshipCountryItem.endDate,
                                            inputProps: {
                                                name: 'citizenshipEndDate',
                                                id: 'citizenshipEndDate' + citizenshipCountryItemIndex
                                            },
                                            helperText: lodashGet(citizenshipCountryItem, 'errors.endDate') || '',
                                            onChange: (date) => {
                                                if (moment(date, true).isValid()) {
                                                    fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex], endDate: date, errors: { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex].errors, endDate: false } };
                                                    setFieldsData({ ...fieldsData });
                                                }
                                                else {
                                                    fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex] = { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex], endDate: null, errors: { ...fieldsData.previousCitizenOfCountries[citizenshipCountryItemIndex].errors, endDate: false } };
                                                    setFieldsData({...fieldsData });
                                                }
                                            },
                                            label: getLanguageValue('register-individual.stage2-citizenship-end-date') + ' *'
                                        }}
                                    />
                                </Grid>
                                <Grid item className={"single-field-container"} md={3}>
                                    {getDisplayedActionForPreviousCitizenship(citizenshipCountryItem, citizenshipCountryItemIndex)}
                                </Grid>
                            </Grid>;
                        })}
                    </div>
                    {fieldsData.previousCitizenOfCountries.length > 1 && <div>{addPreviousCitizenshipButton}</div>}
                    </>}
                </div>}
                <div className={"email-container"}>
                    <div className={"email-important-notification"}>{getLanguageValue('register-individual.stage2-email-address-info')}</div>
                    <Grid container spacing={3}>
                        <Grid className={"single-field-container"} item md={6}>
                            <GenericFieldComponent
                                type={'textfield'}
                                fieldProps={{
                                    className: 'field-component' + (fieldErrors.email ? ' field-error' : ''),
                                    disabled: (props.individualKey || '').length > 0,
                                    error: fieldErrors.email,
                                    value: fieldsData.email,
                                    inputProps: {
                                        name: 'email'
                                    },
                                    helperText: fieldErrors.email || '',
                                    onChange: DEFAULT_ON_CHANGE,
                                    label: getLanguageValue('register-individual.stage2-email-address') + ' *'
                                }}
                            />
                        </Grid>
                        <Grid className={"single-field-container"} item md={6}>
                            <GenericFieldComponent
                                type={'textfield'}
                                fieldProps={{
                                    className: 'field-component' + (fieldErrors.emailConfirm ? ' field-error' : ''),
                                    disabled: (props.individualKey || '').length > 0,
                                    error: fieldErrors.emailConfirm,
                                    value: (props.individualKey || '').length > 0 ? fieldsData.email : fieldsData.emailConfirm,
                                    inputProps: {
                                        name: 'emailConfirm'
                                    },
                                    helperText: fieldErrors.emailConfirm || '',
                                    onChange: DEFAULT_ON_CHANGE,
                                    label: getLanguageValue('register-individual.stage2-email-address-confirm') + ' *'
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <RegisterBottomButtons
                actions={{
                    loaders,
                    isNextDisabled,
                    next,
                    exit
                }}
            />
            {loaders.next ? <div className="loading-notification-container"><br/>{getLanguageValue('register-individual.stage2-loading-notification')}<br/></div> : null}
            {registerSubProcessOpened && <RegisterStage2SubProcess actions={registerSubProcessActions} replacements={[ { value: '{email}', newValue: fieldsData.email }]} uploadedStageFiles={fileUploadsData} minorGoingWithParent={minorGoingWithParent} />}
            {fieldErrors.submit ? <div className={"submit-error"}>{fieldErrors.submit}</div> : null}
            {showMinorWarning && <ModalComponent
                isOpen={isModalOpened}
                modalClassName={'register-individual-stage2-minor-warning-modal'}
                showTitleCloseButton={true}
                onClose={(src) => { setIsModalOpened(false); }}
                title={getLanguageValue('register-individual.stage2-minor-warning-title')}
                content={<ModalComponentStyle>
                    <ModalOverrideGlobalStyle />
                    <div className={'html-container'} dangerouslySetInnerHTML={{ __html: getLanguageValue('register-individual.stage2-minor-warning-text') }} />
                    <div id="modal-buttons">
                        <br />
                        <Button onClick={() => { setShowMinorWarning(false); setIsModalOpened(false); setMinorGoingWithParent(true); }}>{getLanguageValue('register-individual.stage2-minor-with-existing-visa-parent-close-button')}</Button>
                        <br/><br/>
                        <Button onClick={() => { setShowMinorWarning(false); setIsModalOpened(false); navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group'); }}>{getLanguageValue('register-individual.stage2-minor-with-group-visa-close-button')}</Button>
                    </div>
                </ModalComponentStyle>}
                />}
        </div>
    </RegisterStage2Style>;

    function isNextDisabled() {
        return false;
    }

    function areImagesInactive() {
        return (isInactiveField('stage2', 'passport') && isInactiveField('stage2', 'passportFace'));
    }

    function isImageRequired(imageKey) {
        return !isInactiveField('stage2', imageKey);
    }

    function isPassportBacksideRequired() {
        if (fieldsData.countryOfCitizenship.toLowerCase() == 'india') {
            return true;
        }
        return false;
    }

    function isArrivalDateWithVisaTypeInvalid(arrivalDate, visaType) {
        if (!arrivalDate) {
            arrivalDate = fieldsData.arrivalDate;
        }
        if (!visaType) {
            visaType = fieldsData.visaType;
        }
        if (arrivalDate && visaType != 'select') {
            let workDaysCounter = 0;
            let timeDelta = moment(moment().format('YYYY-MM-DD'));
            if (CONSTANTS.ISRAEL_CLOCK_TIME > '14:00:00') {
                // if today has passed 14:00 in israel - move to next day
                //timeDelta.add(1, 'days');
            }
            while (moment(arrivalDate).diff(moment(timeDelta), 'days') > 0) {
                timeDelta.add(1, 'days');
              if (['0', '6'].indexOf(String(timeDelta.format('d'))) == -1) {
                  workDaysCounter++;
                }
            }
            if (CONSTANTS.VISA_TYPES_TIMING_MAPPING[visaType] > workDaysCounter) {
                return true;
                //setFieldErrors({...fieldErrors, arrivalDate: getLanguageValue('register-individual-stage2-arrival-date-too-early') });
            }
            else if (fieldErrors.arrivalDate) {
                //setFieldErrors({...fieldErrors, arrivalDate: false });
            }
        }
        return false;
    }

    async function next() {
        // goto next stage
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            notifyClientError({ data: fieldsData, errors: fieldErrors });
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (actions && actions.nextStage) {
            if (props.individualKey) {
                if (loaders.next) {
                    return;
                }
                setLoaders({ ...loaders, next: true });
                let duplicateData = lodashCloneDeep(fieldsData);
                for (let item of duplicateData.previousCitizenOfCountries) {
                    delete item.errors;
                }
                for (let item of duplicateData.currentlyCitizenOfCountries) {
                    delete item.errors;
                }
                // cleanup if there are no values
                if (duplicateData.previousCitizenOfCountries.length == 1 && duplicateData.previousCitizenOfCountries[0].startDate == null) {
                    duplicateData.previousCitizenOfCountries = [];
                }
                if (duplicateData.currentlyCitizenOfCountries.length == 1 && duplicateData.currentlyCitizenOfCountries[0].howCitizenshipReceived == 'select') {
                    duplicateData.currentlyCitizenOfCountries = [];
                }
                delete duplicateData.emailConfirm;
                let duplicateDataForState = lodashCloneDeep(duplicateData);
                duplicateData.visaExpeditedFlag = (duplicateData.visaExpeditedFlag == true ? 1 : 0);
                //let duplicateDataForState = JSON.parse(JSON.stringify(duplicateData));
                delete duplicateData.email;


                // fix date objects into string in format YYYY-MM-DD
                convertDateFieldsIntoString(duplicateData, 'YYYY-MM-DD');
                // go over inner fields of previousCitizenOfCountries
                if (duplicateData.previousCitizenOfCountries.length) {
                for (let loop = 0 ; loop < duplicateData.previousCitizenOfCountries.length ; ++loop) {
                  let item = duplicateData.previousCitizenOfCountries[loop];
                    convertDateFieldsIntoString(item, 'YYYY-MM-DD');
                  }
                }
                // already verified - immediately save, upload, and go to next stage
                let url = getApiRoot() + 'update-process/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        stage: '2',
                        key: props.individualKey,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage2'),
                        recaptchaToken
                    })
                };
                try {
                    let result = await (await fetch(url,options)).json();
                    if (!lodashGet(result, 'data.success')) {
                        setLoaders({ ...loaders, next: false });
                        setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
                        return;
                    }
                }
                catch (err) {
                    // nothing to do
                    setLoaders({ ...loaders, next: false });
                    setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
                    return;
                }
                let newFilesArray = (props.uploadedFiles || []);
                let filesToUploadArray = [];
                let uploadFilesChangedFlag = false;
                let fileFieldsKeysArray = [ 'passport', 'passportFace', 'personalIdWithAppendix', 'healthGreenCardOrVaccination'].concat(CONSTANTS.REQUIRED_IMAGES_FOR_MINOR);
                if (isPassportBacksideRequired()) {
                    fileFieldsKeysArray.push('passportBackside');
                }
                // replace uploaded file (optional, since there is a previous file)
                for (let fileField of fileFieldsKeysArray) {
                    if (lodashGet(fileUploadsData, fileField + '.base64') && !lodashGet(fileUploadsData, fileField + '.previewOnly')) {
                        uploadFilesChangedFlag = true;
                        newFilesArray = newFilesArray.filter((item) => item.fileType != fileField);
                        newFilesArray.push({
                            fileType: fileField,
                            filename: lodashGet(fileUploadsData, fileField + '.filename') || getExtensionByFileContent(lodashGet(fileUploadsData, fileField + '.base64')),
                            fileContent: lodashGet(fileUploadsData, fileField + '.base64')
                        });
                        filesToUploadArray.push({
                            fileType: fileField,
                            filename: lodashGet(fileUploadsData, fileField + '.filename') || getExtensionByFileContent(lodashGet(fileUploadsData, fileField + '.base64')),
                            fileContent: lodashGet(fileUploadsData, fileField + '.base64')
                        });
                    }
                }
                if (uploadFilesChangedFlag) {
                    // create multiple uploads
                    let fileTypes = filesToUploadArray.map((item) => item.fileType);
                    let promises = [];
                    for (let fileType of fileTypes) {
                        let boundPromise = executeSingleRequest.bind({
                            url: getApiRoot() + 'upload-files/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp),
                            requestBody: {
                                key: props.individualKey,
                                reset: true,
                                filesArray: [
                                    {
                                        fileType,
                                        filename: lodashGet(filesToUploadArray.find((item) => item.fileType == fileType), 'filename'),
                                        fileContent: lodashGet(filesToUploadArray.find((item) => item.fileType == fileType), 'fileContent')
                                    }
                                ]
                            }
                        });
                        promises.push(boundPromise());
                    }
                    let results = await Promise.all(promises);
                    for (let result of results) {
                        if (!lodashGet(result, 'data.success')) {
                            setLoaders({ ...loaders, next: false });
                            setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-file-upload-error') });
                            return;
                        }
                    }
                }
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerIndividualStagesData.stage2Data',
                        value: duplicateDataForState
                    },{
                        path: 'registerIndividualStagesData.uploadedFiles',
                        value: newFilesArray
                    }]
                });
                actions.nextStage();
            }
            else {
                // (1) update state (2) open modal for verification process
                let dataToUpdate = lodashCloneDeep(fieldsData);
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerIndividualStagesData.stage2Data',
                        value: dataToUpdate
                    }]
                });
                setRegisterSubProcessOpened(true);
                setRegisterSubProcessActions({
                    onClose: async function(reason) {
                        setRegisterSubProcessOpened(false);
                        if (reason == 'abort') {
                            // nothing to do
                        }
                        else if (reason == 'success') {
                            actions.nextStage();
                        }
                    }
                });
            }
        }
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic', 'pdf'].indexOf(fileTypeResultExtension) == -1) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-type-error'), base64: null, filename: '' } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].destroy();
                }, 100);
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-too-small-error'), base64: null, filename: '' } });
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-size-error'), base64: null, filename: '' } });
                return;
            }
            if (fileTypeResultExtension == 'pdf') {
                // destory crop tool
                setTimeout(() => {
                    cropHandlerControls[fieldName].destroy();
                }, 100);
                // enable display of pdf
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: false, base64: reader.result, filename: localFilename + '.' + fileTypeResultExtension, showAsPdf: true, editMode: true } });
                return;
            }
            // activate crop tool
            setTimeout(() => {
                setFileUploadsData({ ...fileUploadsData, [fieldName]: { error: false, base64: reader.result, filename: localFilename + '.' + fileTypeResultExtension, editMode: true } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    function exit() {
        window.location.href = getContextRoot();
    }

    function areAllCurrentCitizenshipValuesOk() {
        for (let loop = 0 ; loop < fieldsData.currentlyCitizenOfCountries.length ; ++loop) {
            const item = fieldsData.currentlyCitizenOfCountries[loop];
            if (!item.countryOfCitizenship) {
                return false;
            }
            if (item.howCitizenshipReceived == 'select') {
                return false;
            }
            if (item.howCitizenshipReceived == 'other' && !item.other) {
                return false;
            }
        }
        return true;
    }

    function areAllPreviousCitizenshipValuesOk() {
        for (let loop = 0 ; loop < fieldsData.previousCitizenOfCountries.length ; ++loop) {
            const item = fieldsData.previousCitizenOfCountries[loop];
            if (!item.countryOfCitizenship) {
                return false;
            }
            if (item.startDate == null) {
                return false;
            }
            if (item.endDate == null) {
                return false;
            }
        }
        return true;
    }

    function getDisplayedActionForCurrentCitizenship(item, itemIndex) {

        if (itemIndex === 0) {
            if (fieldsData.currentlyCitizenOfCountries.length === 1) {
                // 1 item - use add button
                return addCurrentCitizenshipButton;
            }
            else {
                // more than 1 item - use delete button
                return deleteCurrentCitizenshipButton({ itemIndex });
            }
        }
        else {
            // use delete button
            return deleteCurrentCitizenshipButton({ itemIndex });
        }
    }

    function getDisplayedActionForPreviousCitizenship(item, itemIndex) {

        if (itemIndex === 0) {
            if (fieldsData.previousCitizenOfCountries.length === 1) {
                // 1 item - use add button
                return addPreviousCitizenshipButton;
            }
            else {
                // more than 1 item - use delete button
                return deletePreviousCitizenshipButton({ itemIndex });
            }
        }
        else {
            // use delete button
            return deletePreviousCitizenshipButton({ itemIndex });
        }
    }

    function validate(config) {
        let errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of FIELDS) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }

        let localFileUploadsData = { ...fileUploadsData };
        let fileUploadsDataChanged = false;
        if (!areImagesInactive()) {
            if (!isInactiveField('stage2', 'passport')) {
                if (!props.stage2UploadedPassport && !lodashGet(fileUploadsData, 'passport.base64')) {
                    fileUploadsDataChanged = true;
                    errors.push('passportFileUpload');
                    if (lodashGet(config, 'setErrors')) {
                        localFileUploadsData.passport = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                    }
                }
            }
            if (!isInactiveField('stage2', 'passportFace')) {
                if (!props.stage2UploadedPassportFace && !lodashGet(fileUploadsData, 'passportFace.base64')) {
                    fileUploadsDataChanged = true;
                    errors.push('passportFileUpload');
                    if (lodashGet(config, 'setErrors')) {
                        localFileUploadsData.passportFace = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                    }
                }
            }
            if (isPassportBacksideRequired()) {
                if (!props.stage2UploadedPassportBackside && !lodashGet(fileUploadsData, 'passportBackside.base64')) {
                    fileUploadsDataChanged = true;
                    errors.push('passportBacksideFileUpload');
                    if (lodashGet(config, 'setErrors')) {
                        localFileUploadsData.passportBackside = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                    }
                }
            }
            if (!isInactiveField('stage2', 'personalIdWithAppendix')) {
                if (!props.stage2UploadedPersonalIdWithAppendix && !lodashGet(fileUploadsData, 'personalIdWithAppendix.base64')) {
                    fileUploadsDataChanged = true;
                    errors.push('personalIdWithAppendixFileUpload');
                    if (lodashGet(config, 'setErrors')) {
                        localFileUploadsData.personalIdWithAppendix = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                    }
                }
            }
            if (!isInactiveField('stage2', 'healthGreenCardOrVaccination')) {
                if (!props.stage2UploadedHealthGreenCardOrVaccination && !lodashGet(fileUploadsData, 'healthGreenCardOrVaccination.base64')) {
                    fileUploadsDataChanged = true;
                    errors.push('healthGreenCardOrVaccination');
                    if (lodashGet(config, 'setErrors')) {
                        localFileUploadsData.healthGreenCardOrVaccination = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                    }
                }
            }
        }
        if (minorGoingWithParent) {

            if (CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('birthCertificate') > -1 && !props.stage2UploadedBirthCertificate && !lodashGet(fileUploadsData, 'birthCertificate.base64')) {
                fileUploadsDataChanged = true;
                errors.push('birthCertificateUpload');
                if (lodashGet(config, 'setErrors')) {
                    localFileUploadsData.birthCertificate = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                }
            }
            if (CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('parentPassport') > -1 && !props.stage2UploadedParentPassport && !lodashGet(fileUploadsData, 'parentPassport.base64')) {
                fileUploadsDataChanged = true;
                errors.push('parentPassportUpload');
                if (lodashGet(config, 'setErrors')) {
                    localFileUploadsData.parentPassport = { error: getLanguageValue('register-field-errors-must-upload'), base64: null, filename: '' };
                }
            }
        }

        // validate arrivalDate
        if (fieldsData.arrivalDate) {
            if (!moment(fieldsData.arrivalDate, 'YYYY-MM-DD', true).isValid()) {
                if (lodashGet(config, 'setErrors')) {
                    fieldErrors.arrivalDate = getLanguageValue('register-field-errors-invalid');
                }
                errors.push('arrivalDate');
            }
            else {
                if (moment(fieldsData.arrivalDate).diff(moment(), 'days') < 0) {
                    if (lodashGet(config, 'setErrors')) {
                        fieldErrors.arrivalDate = getLanguageValue('register-individual.stage2-date-cannot-be-before-today');
                    }
                    errors.push('arrivalDate');
                }
                else if (moment(fieldsData.arrivalDate).diff(moment(), 'months') > 6) {
                    if (lodashGet(config, 'setErrors')) {
                        fieldErrors.arrivalDate = getLanguageValue('register-individual-stage2-arrival-date-too-late');
                    }
                    errors.push('arrivalDate');
                }
                else if (moment(fieldsData.arrivalDate).diff(moment(), 'days') > 0) {
                    if (isArrivalDateWithVisaTypeInvalid(null, null)) {
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors.arrivalDate = getLanguageValue('register-individual-stage2-arrival-date-too-early');
                        }
                        errors.push('arrivalDate');
                    }
                }
            }
        }
        else if (!fieldsData.arrivalDate) {
            if (lodashGet(config, 'setErrors')) {
                fieldErrors.arrivalDate = getLanguageValue('register-field-errors-empty');
            }
            errors.push('arrivalDate');
        }
      //  if (!props.isIndividualLinkedToGroup && ['messenger-d-self-p', 'messenger-d-messenger-p'].indexOf(fieldsData.deliveryType) > -1) {
      //      if (fieldsData.deliveryDestination == 'select') {
      //          if (lodashGet(config, 'setErrors')) {
      //              fieldErrors.deliveryDestination = getLanguageValue('register-field-errors-must-select');
      //          }
      //          errors.push('arrivalDate');
      //      }
      //  }
      //  if (fieldsData.borderOfEntry == 'select') {
      //      if (lodashGet(config, 'setErrors')) {
      //          fieldErrors.borderOfEntry = getLanguageValue('register-field-errors-must-select');
      //      }
      //      errors.push('borderOfEntry');
      //  }
        if (fieldsData.purposeOfTravel == 'select') {
            if (lodashGet(config, 'setErrors')) {
                fieldErrors.purposeOfTravel = getLanguageValue('register-field-errors-must-select');
            }
            errors.push('purposeOfTravel');
        }
        let changedFieldsData = false;
        if (fieldsData.questionAreYouACitizenOfOtherCountry == 'select') {
            errors.push('questionAreYouACitizenOfOtherCountry');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['questionAreYouACitizenOfOtherCountry'] = getLanguageValue('register-field-errors-must-select');
            }
        }
        else if (fieldsData.questionAreYouACitizenOfOtherCountry == 'yes') {
            let index = 0;
            for (let currentlyCitizenOfCountriesItem of fieldsData.currentlyCitizenOfCountries) {
                if (lodashGet(config, 'setErrors')) {
                    changedFieldsData = true;
                    currentlyCitizenOfCountriesItem.errors = {};
                }
                if (currentlyCitizenOfCountriesItem.countryOfCitizenship == 'select') {
                    errors.push('questionAreYouACitizenOfOtherCountry_countryOfCitizenship' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        currentlyCitizenOfCountriesItem.errors['countryOfCitizenship'] = getLanguageValue('register-field-errors-must-select');
                    }
                }
                if (currentlyCitizenOfCountriesItem.howCitizenshipReceived == 'select') {
                    errors.push('questionAreYouACitizenOfOtherCountry_howCitizenshipReceived' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        currentlyCitizenOfCountriesItem.errors['howCitizenshipReceived'] = getLanguageValue('register-field-errors-must-select');
                    }
                }
                if (currentlyCitizenOfCountriesItem.howCitizenshipReceived == 'other' && !DEFAULT_EMPTY_VALUE_VALIDATOR(currentlyCitizenOfCountriesItem.other)) {
                    errors.push('questionAreYouACitizenOfOtherCountry_other' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        currentlyCitizenOfCountriesItem.errors['other'] = getLanguageValue('register-field-errors-empty');
                    }
                }
                ++index;
            }
        }
        if (fieldsData.questionWereYouACitizenOfOtherCountry == 'select') {
            errors.push('questionWereYouACitizenOfOtherCountry');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['questionWereYouACitizenOfOtherCountry'] = getLanguageValue('register-field-errors-must-select');
            }
        }
        else if (fieldsData.questionWereYouACitizenOfOtherCountry == 'yes') {
            let index = 0;
            for (let previousCitizenOfCountriesItem of fieldsData.previousCitizenOfCountries) {
                if (lodashGet(config, 'setErrors')) {
                    changedFieldsData = true;
                    previousCitizenOfCountriesItem.errors = {};
                }
                if (previousCitizenOfCountriesItem.countryOfCitizenship == 'select') {
                    errors.push('questionWereYouACitizenOfOtherCountry_countryOfCitizenship' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        previousCitizenOfCountriesItem.errors['countryOfCitizenship'] = getLanguageValue('register-field-errors-must-select');
                    }
                }
                let el;
                el = document.querySelector('#citizenshipStartDate' + index);
                if (el) {
                    if (!DEFAULT_EMPTY_VALUE_VALIDATOR(previousCitizenOfCountriesItem.startDate) || !moment(el.value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) {
                        errors.push('questionWereYouACitizenOfOtherCountry_startDate' + index);
                        if (lodashGet(config, 'setErrors')) {
                            changedFieldsData = true;
                            previousCitizenOfCountriesItem.errors['startDate'] = el.value.length ? getLanguageValue('register-field-errors-invalid') : getLanguageValue('register-field-errors-empty');
                        }
                    }
                }
                el = document.querySelector('#citizenshipEndDate' + index);
                if (el) {
                    if (!DEFAULT_EMPTY_VALUE_VALIDATOR(previousCitizenOfCountriesItem.endDate) || !moment(el.value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) {
                        errors.push('questionWereYouACitizenOfOtherCountry_endDate' + index);
                        if (lodashGet(config, 'setErrors')) {
                            changedFieldsData = true;
                            previousCitizenOfCountriesItem.errors['endDate'] = el.value.length ? getLanguageValue('register-field-errors-invalid') : getLanguageValue('register-field-errors-empty');
                        }
                    }
                }
                ++index;
            }
        }

        if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.email)) {
            errors.push('email');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['email'] = !fieldsData.email.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
            }
        }
        if (!props.individualKey) {
            if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.emailConfirm)) {
                errors.push('emailConfirm');
                if (lodashGet(config, 'setErrors')) {
                    fieldErrors['emailConfirm'] = !fieldsData.emailConfirm.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
                }
            }
            else if (new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.email) && fieldsData.email != fieldsData.emailConfirm) {
                errors.push('email');
                if (lodashGet(config, 'setErrors')) {
                    fieldErrors['email'] = getLanguageValue('register-field-errors-no-match-to-confirm-field');
                    fieldErrors['emailConfirm'] = ' ';
                }
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
            if (changedFieldsData) {
                setFieldsData(fieldsData);
            }
            if (fileUploadsDataChanged) {
                setFileUploadsData(localFileUploadsData);
            }
        }
        return errors;
    }

    function getRelevantUploadedFilesData() {
        let output = {};
        const acceptedFileTypesArray = ['passport', 'passportFace', 'passportBackside', 'personalIdWithAppendix', 'healthGreenCardOrVaccination'].concat(CONSTANTS.REQUIRED_IMAGES_FOR_MINOR);
        if (props.uploadedFiles) {
            for (let loop = 0 ; loop < props.uploadedFiles.length ; ++loop) {
                let item = props.uploadedFiles[loop];
                if (acceptedFileTypesArray.indexOf(item.fileType) > -1) {
                    output[item.fileType] = {
                        error: false,
                        filename: item.filename,
                        base64: item.fileContent,
                        previewOnly: true,
                        ...(getExtensionByFileContent(item.fileContent) == 'pdf' ? {
                            showAsPdf: true
                        } : {})
                    };
                }
            }
        }
        return output;
    }

    function cropHandlerCallback(croppedImage) {
        lodashSet(fileUploadsData, this.fileType + '.editMode', false);
        if (croppedImage !== false) {
            fileUploadsData[this.fileType].base64 = croppedImage;
            lodashSet(fileUploadsData, this.fileType + '.previewOnly', false);
        }
        setFileUploadsData({ ...fileUploadsData });
    }
}

const RegisterStage2Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .row-container {
    padding-bottom: 20px;
  }
  .sample-image {
    max-width: 100%;
    &.passport-backside {
      @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        max-width: 500px;
      }
    }
    &.birth-certificate {
      @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        max-width: 500px;
      }
    }
    &.personal-id-with-appendix, &.health-green-card-or-vaccination {
      @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        width: 500px;
      }
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
        width: 100%;
      }
    }
  }
  .upload-general-warning-message {
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-bottom: 5px;
    text-align: right;
    html.ltr & {
      text-align: left;
    }
  }
  .upload-passport-face-container {
    width: 100%;
  }
  .upload-passport-face-button-container {
    width: 100%;
  }
  .upload-passport-face-image-container {
    width: 100%;
    max-width: 350px;
  }
  .upload-personal-id-with-appendix-image-container {
    max-width: 350px;
    margin-bottom: 10px;
  }
  .upload-health-green-card-or-vaccination-image-container {
    max-width: 350px;
    margin-bottom: 10px;
  }
  .upload-passport-backside-button-container {
    width: 100%;
  }
  .upload-personal-id-with-appendix-container {
    margin-top: 10px;
    width: 100%;
    .upload-personal-id-with-appendix-button-container {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
        width: 100%;
      }
    }
  }
  .upload-health-green-card-or-vaccination-container {
    margin-top: 10px;
    width: 100%;
    .upload-health-green-card-or-vaccination-button-container {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
        width: 100%;
      }
    }
  }
  .sample-image-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      justify-content: center;
    }
  }
  .upload-birth-certificate-image-container {
    width: 100%;
    max-width: 600px;
  }
  .upload-birth-certificate-button-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  .upload-parent-passport-image-container {
    max-width: 500px;
  }
  .upload-parent-passport-button-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
  }
  .file-upload-button {
    width: 500px;
    height: 50px;
    border: 1px solid black;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
    &.field-error {
      border-color: ${CSS_COLORS.FIELD_WARNING};
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .file-upload-edit-button {
    width: 100%;
    border: 1px solid black;
  }
  .hidden-file-field {
    display: none;
  }
  .file-upload-error {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-top: 15px;
  }
  .uploaded-image {
    max-width: 100%;
    border: 1px dashed black;
    margin-top: 15px;
    &.passport, &.passport-backside {
      max-width: 500px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
        max-width: 100%;
      }
    }
    &.hidden {
      display: none;
    }
  }
  .select-visa-type-container, .arrival-date-container, .select-visa-delivery-type-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: 100%;
    }
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      min-width: 400px;
    }
  }
  .select-visa-delivery-explanation-container {
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .visa-type-warning-container {
    margin-top: 8px;
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .fields-container {
    //width: 100%;
    //margin-bottom: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .no-top-label {
    .MuiInput-formControl {
      margin-top: 0;
    }
    .MuiInput-input {
      padding-top: 0;
    }
  }
  .arrival-date-container {
    margin-top: 20px;
  }
  .arrival-date-business-days-note {
    margin-top: 20px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .expedited-visa-component {
    html.rtl & {
      margin-right: -10px;
    }
  }
  .delivery-self-address-container {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-bottom: 10px;
  }
  .visa-delivery-destination-container {
    margin-top: 10px;
  }
  .question-label-container {
    width: 300px;
  }
  .purpose-of-travel, .border-of-entry {
    max-width: 500px;
  }
  .is-citizen-question-container {
    margin-bottom: 20px;
  }
  .other-countries-citizenship-list-container {
    margin-top: 20px;
  }
  .other-countries-citizenship-list-item-row {
      border: 1px solid black;
      padding: 10px;
      &.even {
        background-color: #eee;
      }
  }
  .citizenship-start-date, .citizenship-end-date {
    .MuiInputLabel-formControl {
      @media (max-width: 1600px) {
        top: -12px;
        max-width: 150px;
      }
    }
  }
  .add-button-container, .delete-button-container {
    margin-top: 15px;
    button:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .delete-button-container {
    svg {
      color: #A00000;
    }
  }
  #visaType, #deliveryDestination {
    @media (max-width: 550px) {
      font-size: 3vw;
      height: auto;
    }
  }
  .email-container {
    margin-top: 25px;
  }
  .email-important-notification {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .loading-notification-container {
    font-weight: bold;
    color: blue;
  }
  .submit-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const ModalComponentStyle = styled.div`
    #modal-buttons {
      text-align: center;
      padding-bottom: 15px;
      > button {
        width: 380px;
        background-color: #fc3;
        @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
          width: 100%;
        }
      }
    }
`;

const ModalOverrideGlobalStyle = createGlobalStyle`
  #modal-component.register-individual-stage2-minor-warning-modal {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      top: 55px !important;
    }
    #modal-title {
      font-size: 33px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 24px;
      }
    }
    #modal-content {
      font-size: 22px;
    }
    .html-container {
      padding: 10px 10px;
      display: block;
      font-size: 16px;
    }
  }
`;

const RegisterStage2 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        stage2UploadedPassport: lodashGet(state, 'registerIndividualStagesData.stage2UploadedPassport') || false,
        stage2UploadedPassportFace: lodashGet(state, 'registerIndividualStagesData.stage2UploadedPassportFace') || false,
        stage2UploadedPassportBackside: lodashGet(state, 'registerIndividualStagesData.stage2UploadedPassportBackside') || false,
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        uploadedFiles: lodashGet(state, 'registerIndividualStagesData.uploadedFiles'),
        isIndividualLinkedToGroup: lodashGet(state, 'globalControls.isIndividualLinkedToGroup')
    }),
    {})(RegisterStage2Component);

export default RegisterStage2;
